import { render, staticRenderFns } from "./CompanyOnboardingAddress.vue?vue&type=template&id=5a4ab508&scoped=true&"
import script from "./CompanyOnboardingAddress.vue?vue&type=script&lang=js&"
export * from "./CompanyOnboardingAddress.vue?vue&type=script&lang=js&"
import style0 from "./CompanyOnboardingAddress.vue?vue&type=style&index=0&id=5a4ab508&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4ab508",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VCombobox,VProgressCircular,VRow,VTextField})
